import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";
import {AlerteComponent} from "./AlerteComponent";

export class AlerteModule extends PewComponent {

  constructor(element, options) {
    let defaultOptions = {
      componentWrappersSelector: "[data-alert-wrap]",
      alertCloseTriggerSelector: ".close-trigger",
      alertTransitionSpeed: 400
    };
    super(element, Object.assign(defaultOptions, options));

    this.$panel = null;
  }

  init() {
    this.debug = true;
    this.alertComponents = [];
    this.EventManager = window.EventManager || $(document);
    this.ajaxUrl = wonderwp.resturl + 'wwp-alerte/v1';
    const cacheEnabled = this.element.data('cache-enabled');
    const hasAlerts = this.element.data('has-alerts');
    if (cacheEnabled && hasAlerts) {
      this.revealDisplayableAlerts();
    } else if (hasAlerts) {
      this.scanAlerts();
    }
  }

  revealDisplayableAlerts() {
    this.scanAlerts();
    for (let i in this.alertComponents) {
      let alerteComponent = this.alertComponents[i],
        isAlertSeen = this.isAlertSeen(alerteComponent.options.id);
      if (!isAlertSeen) {
        alerteComponent.reveal();
      }
    }
  }

  scanAlerts() {
    const $alertComponentsWraps = this.element.find(this.options.componentWrappersSelector);

    if ($alertComponentsWraps.length) {
      $alertComponentsWraps.each((i, alertComponentWrap) => {
        let $wrap = $(alertComponentWrap);
        this.alertComponents[i] = new AlerteComponent($wrap, {
          index: i,
          id: $wrap.attr('data-wrapping-id'),
          closeTriggerSelector: this.options.alertCloseTriggerSelector,
          transitionSpeed: this.options.alertTransitionSpeed,
          onClose: (alerteComponent) => {
            this.storeAlertClosed(alerteComponent);
          }
        });
      });
    }
  }

  getSeenAlerts() {
    if (!this.seenAlerts) {
      let cookieVal = this.getCookieVal('wwp-alerte');
      if (cookieVal === '') {
        cookieVal = '{}';
      }
      this.seenAlerts = JSON.parse(cookieVal);
    }
    return this.seenAlerts;
  }

  isAlertSeen(alerteId) {
    const seenAlerts = this.getSeenAlerts();
    return !!seenAlerts[alerteId];
  }

  getCookieVal(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  storeAlertClosed(alerteComponent) {
    this.log('store alerte closed', alerteComponent);
    $.ajax($.extend({
      url: this.getCloseUrl(alerteComponent.options.id),
    }, this.getCloseAjaxParams()))
  }

  getCloseUrl(alerteId) {
    const requestUrl = this.ajaxUrl + '/close';
    let params = {
      alerte: alerteId
    };
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return requestUrl + '?' + queryString;
  }

  getCloseAjaxParams() {
    return {
      type: 'PUT'
    }
  }

  log(msg) {
    if (this.debug) {
      console.log('[wwp-alert] ', ...arguments);
    }
  }

}

if (window.pew) {
  window.pew.addRegistryEntry({
    key: 'wwp-alerte', domSelector: '.module-alerte', classDef: AlerteModule
  });
}
