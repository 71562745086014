import {SearchComponent} from "./SearchComponent";

export class SearchPanel {
    constructor(passedOptions) {
        this.$markup       = null;
        this.isOpen        = false;
        let defaultOptions = {
            searchLink: '/?s=',
            formSelector: '.search-form'
        };
        this.options       = Object.assign(defaultOptions, passedOptions);
        this.searchComponent = null;
        this.init();
    }

    init() {
        this.create();
        this.setupListeners();
    }

    create() {
        let markup =
                `<div class="search-panel-wrap" role="dialog" aria-modal="true">
                    <div class="search-component">
                        <div class="form-wrap"></div>
                        <div class="search-results-wrap"></div>
                        <button class="close close-inner">&times</button>
                    </div>
                    <button class="close close-outter" tabindex="0" type="button" aria-label="close">&times</button>
                </div>`;

        this.$markup = $(markup);
    }

    setupListeners() {
        this.$markup.find('.close').on('click', () => {
            this.close();
        });
    }

    toggle() {
        //console.log('toggle sees ' + this.isOpen);
        if (this.isOpen) {
            //console.log('toggle calls close');
            this.close();
        } else {
            //console.log('toggle calls open');
            this.open(()=>{
                if(this.searchComponent){
                    this.searchComponent.focusSearchInput();
                }
            });
        }
    }

    open(cb) {
        if (this.searchComponent === null) {
            this.loadSearchForm(this.initSearchComponent.bind(this));
        }
        this.toggleOpened(true);
        cb && cb();
    }

    close(cb) {
        this.toggleOpened(false);
        cb && cb();
    }

    toggleOpened(opened) {
        //console.log('toggling isOpen from ' + this.isOpen + ' to ' + (opened));
        this.isOpen = opened;
        this.toggleWrap();
    }

    toggleWrap() {
        this.$markup.toggleClass('active');
        $('html').toggleClass('has-search');
    }

    loadSearchForm(cb) {
        if (this.options.searchLink) {
            this.$markup.find('.form-wrap').html('<span class="loader"></span>');
            $.get(this.options.searchLink, {}, (searchFormDom) => {
                this.injectSearchFormMarkup($(searchFormDom).find(this.options.formSelector));
                cb && cb();
            });
        } else {
            console.log("[search] Couldn't find this.options.searchLink, can't load search form via ajax")
        }
    }

    injectSearchFormMarkup(searchFormMarkup) {
        this.$markup.find('.form-wrap').html(searchFormMarkup);
    }

    initSearchComponent() {
        this.searchComponent = new SearchComponent(this.$markup.find('.search-component'));
        $(document).on("searchLink:click", (searchLinkEvent,clickEvent)=>{
            clickEvent.preventDefault();
            this.close(()=>{
                window.location.href = clickEvent.currentTarget.getAttribute('href');
            });
        })
    }

}
