/**
 * jeux.js. Created by jeremydesvaux the 16 mai 2014
 */
(function ($, ns) {

    "use strict";

    /**
     * init module scripts, relative to its context (multiple context of the same module may exist in a page)
     * @param jQuery $context wraper div of the module
     */
    var jeux = function (context) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.init();
    };

    jeux.prototype = {
        init: function () {
            this.registerQuestions(this.$context);
        },
        registerQuestions: function ($context) {
            var $questions = $context.find('.question-wrap');
            if ($questions.length) {
                var needVideoPermission = localStorage.getItem('needVideoPermission');
                if (needVideoPermission === null) {
                    needVideoPermission = true;
                }
                $questions.each(function (i, q) {
                    var question = new JeuxQuestion($(q), {
                        needVideoPermission: (needVideoPermission===true)
                    });
                });
            }
        }
    };

    if (ns && ns.app) {
        ns.app.registerModule('jeux', jeux);
    } else {
        window.pew.addRegistryEntry({key: 'wdf-plugin-jeux', domSelector: '.module-jeux', classDef: jeux});
    }

    var JeuxQuestion = function (context, options) {
        this.$context = (context instanceof jQuery) ? context : $(context);
        this.options  = $.extend({
            needVideoPermission: true
        }, options);
        console.log(this.options);
        this.init();
    };

    JeuxQuestion.prototype = {
        init: function () {
            if (this.$context.find('.video-block').length) {
                this.enableVideoSupport();
            }
        },
        enableVideoSupport: function () {
            this.options.$videoBlock = this.$context.find('.video-block');
            this.options.ytPlayer    = null;
            this.registerVideoTrigger();
            this.registerVideoPermission();
        },
        registerVideoTrigger: function () {
            var self = this;

            var $trigger = this.$context.find('.video-trigger');
            $trigger.on('click', function (e) {
                self.options.videoId = $(this).data('video-id');
                e.preventDefault();
                self.mayOpenVideo();
            });

        },
        registerVideoPermission: function () {
            var self = this;
            this.$context.find('.video-permission button').on('click', function (e) {
                if ($(this).hasClass('allow')) {
                    self.videoPermissionGranted();
                } else {
                    self.videoPermissionDenied();
                }
            });
        },
        mayOpenVideo: function () {
            if (this.options.needVideoPermission) {
                this.askForVideoPermission();
            } else {
                this.videoPermissionGranted();
            }
        },
        askForVideoPermission: function () {
            this.options.$videoBlock.addClass('permission-active');
        },
        hideVideoPermission: function () {
            this.options.$videoBlock.removeClass('permission-active');
        },
        videoPermissionGranted: function () {
            var self = this;

            self.options.needVideoPermission = false;
            localStorage.setItem('needVideoPermission', false);

            self.hideVideoPermission();

            $(document).off("onYouTubeIframeAPIReadyCustom");
            $(document).on("onYouTubeIframeAPIReadyCustom", function () {
                var $playerTarget = self.$context.find('.video-player-target');
                if (!$playerTarget.length) {
                    var playerTarget = '<div class="video-player-target"></div>';
                    self.options.$videoBlock.append(playerTarget);
                    $playerTarget = self.$context.find('.video-player-target');
                }
                self.options.ytPlayer = new YT.Player($playerTarget[0], {
                    videoId: self.options.videoId
                });

            });

            if (!document.getElementById('yt-iframe-lib')) {
                var tag            = document.createElement('script');
                tag.id             = 'yt-iframe-lib';
                tag.src            = 'https://www.youtube.com/iframe_api';
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            } else {
                onYouTubeIframeAPIReady();
            }

            this.playVideo();
        },
        videoPermissionDenied: function () {
            this.hideVideoPermission();
        },
        playVideo: function () {
            this.options.$videoBlock.addClass('video-active');

            if (this.options.ytPlayer !== null) {
                this.options.ytPlayer.playVideo();
            }
        }
    };

})(jQuery, window.wonderwp);

if (typeof window.onYouTubeIframeAPIReady !== "function") {
    window.onYouTubeIframeAPIReady = function() {
        $(document).trigger("onYouTubeIframeAPIReadyCustom");
    }
}
