/**
 * SocialWall.js. Created by jeremydesvaux the 16 mai 2014
 */
(function ($, ns) {

    "use strict";

    /**
     * init module scripts, relative to its context (multiple context of the same module may exist in a page)
     * @param context wraper div of the module
     */
    var SocialWall = function (context) {
        this.$context            = (context instanceof jQuery) ? context : $(context);
        this.id                  = this.$context.data('set') && this.$context.data('set') > 0 ? this.$context.data('set') : 0;
        this.paginationComponent = null;
        this.init();
    };

    SocialWall.prototype = {
        init: function () {
            this.registerFilters();
            this.registerModeration();
            this.registerPagination();
        },
        registerFilters: function () {

        },
        registerModeration: function () {
            var t = this;
            t.$context.find('.moderationToggler').on('click', function (e) {
                var $btn  = $(this);
                var $post = $btn.parents('.post-component');
                if ($post.hasClass('loading')) { return; }

                $post.addClass('loading');

                var td       = $btn.hasClass('moderationTogglerModerate') ? 'moderate' : 'publish',
                    postData = {
                        'action': 'SocialApiService.moderatePost',
                        'td': td,
                        'postid': $post.data('id')
                    };

                $.post(ns.ajaxurl, postData, function (res) {
                    if (td === 'moderate') {
                        $btn.addClass('moderationTogglerPublish').removeClass('moderationTogglerModerate').text('Publier');
                        $post.removeClass('post-active').addClass('post-moderated');
                    } else {
                        $btn.addClass('moderationTogglerModerate').removeClass('moderationTogglerPublish').text('Modérer');
                        $post.removeClass('post-moderated').addClass('post-active');
                    }
                    $post.removeClass('loading');
                });

            });
        },
        registerPagination: function () {
            var t                   = this,
                paginationComponent = null;

            if (ns && ns.app) {
                paginationComponent = ns.app.getComponent('pagination');
            } else {
                var paginationPewEntry = window.pew.getRegistryEntry('pagination');
                if (paginationPewEntry) {
                    paginationComponent = new paginationPewEntry.classDef();
                }
            }

            if (paginationComponent !== null) {
                if (this.paginationComponent === null) {
                    this.paginationComponent = paginationComponent.init(t.getPaginationOpts(), t.getPaginationExtensions());
                    this.setupPaginationListeners();
                } else {
                    this.paginationComponent.reinitialize();
                }
            }
        },
        getPaginationOpts: function () {
            return {
                container: '.set-' + this.id + ' .post-container',
                item: '.post-component',
                pagination: '.set-' + this.id + ' .pagination',
                next: '.set-' + this.id + ' .pagination .next a'
            };
        },
        getPaginationExtensions: function () {
            return [
                new IASTriggerExtension({offset: 2}),
                new IASSpinnerExtension(),
                new IASNoneLeftExtension()
            ];
        },
        setupPaginationListeners: function () {
            var t = this;
            this.paginationComponent.on('loaded', function (data, items) {
                t.onLoaded(data, $(items));
            });
            this.paginationComponent.on('rendered', function (items) {
                t.onRendered($(items));
            });
        },
        onLoaded: function (data, $items) {
            //console.log('Loaded ' + $items.length + ' items from server');
        },
        onRendered: function ($items) {
            this.registerModeration();
            //console.log('Rendered ' + $items.length + ' items');
        }
    };

    if (ns && ns.app) {
        ns.app.registerModule('SocialWall', SocialWall);
    } else {
        window.pew.addRegistryEntry({key: 'wdf-plugin-social', domSelector: '.module-SocialWall', classDef: SocialWall});
    }

})(jQuery, window.wonderwp);
