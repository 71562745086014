import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class TimelineComponent extends PewComponent {
  init() {
    this.logDebug('Created a new timeline component with element : ', this.element);
    this.steps = [];
    this.activeStep = 0;
    this.$timeline = this.element.find('.timeline');
    this.hasFallbackMobileTimeline = this.$timeline.hasClass('fallback-mobile-timeline');
    this.registerSteps(this.element.find('li.scene-list-item'));
  }

  registerSteps($listItems) {
    this.logDebug('Registering steps with ' + $listItems.length + ' list items : ', $listItems);

    if (this.hasFallbackMobileTimeline) {
      this.registerMobileTimeline();
      this.element.find('.timeline-mobile select').change((e) => {
        this.scrollToStep(e.target.selectedIndex);
      });
    }

    $listItems.each((i, li) => {
      this.steps[i] = $(li);
      let $thisLink = $(li).find('a');
      $thisLink.off('click');
      $thisLink.on('click', (e) => {
        e.preventDefault();
        this.scrollToStep(i);
      })
    })
  }

  deactivateStep(i) {
    i = i || this.activeStep;
    let $stepToDeactivate = this.steps[i];
    this.logDebug('Deactivating step ' + i, $stepToDeactivate);
    if ($stepToDeactivate.length) {
      $stepToDeactivate.removeClass('active');
    }
  }

  activateStep(i) {
    if (this.steps[i]) {
      let $stepToActivate = this.steps[i];

      if (this.hasFallbackMobileTimeline) {
        this.element.find('.timeline-mobile select').prop('selectedIndex', parseInt(i, 10));
      }

      this.logDebug('Activating step ' + i, $stepToActivate);
      $stepToActivate.addClass('active');
      let $parentCatStep = $stepToActivate.parents('.category-list-item');
      if ($parentCatStep.length) {
        $parentCatStep.addClass('active-cat')
        $parentCatStep.siblings().removeClass('active-cat');
      }
      this.activeStep = i;
    }
  }

  scrollToStep(i) {
    if (this.steps[i]) {
      this.logDebug('Asking to scroll to step ', i);
      this.options.scrollToStepCallback && this.options.scrollToStepCallback(i);
    }
  }

  scrollToPosition(positionName) {
    this.logDebug('Asking to scroll to position ', positionName);
    var i = -1;

    if (positionName === 'first') {
      i = 0;
    } else if (positionName === 'last') {
      i = parseInt(this.steps.length) - 1;
    } else if (positionName === 'prev') {
      i = parseInt(this.activeStep) - 1;
      if (i < 0) {
        i = 0;
      }
    } else if (positionName === 'next') {
      i = parseInt(this.activeStep) + 1;
      if (i > this.steps.length) {
        i = this.steps.length;
      }
    }

    if (i >= 0) {
      return this.scrollToStep(i);
    }
  }

  logDebug() {
    if (this.options.debug) {
      console.info('[Parcours][Timeline]', ...arguments);
    }
  }

  registerMobileTimeline() {
    const container = document.createElement('div');
    container.classList.add('timeline-mobile');
    const select = document.createElement('select');
    const children = this.$timeline.find(' > li');

    children.each((index, element) => {

      if ($(element).hasClass('category-list-item')) {
        const group = document.createElement('optgroup');
        const $groupLink = $(element).find('> a')
        group.setAttribute('label', $groupLink.text().trim())

        $(element).find('.scene-list-item').each((index, optionElement) => {
          const { href, text } = this.getListItemData(optionElement);

          const option = createOption(href, text);

          group.appendChild(option);
        });

        select.appendChild(group);
      } else {
          const { href, text } = this.getListItemData(element);
          const option = this.createOption(href, text);

          select.appendChild(option);
      }
    });

    container.appendChild(select)
    this.element.append(container);
  }

  createOption (value, text) {
    const option = document.createElement('option');
    option.setAttribute('value', value);
    option.innerText = text;

    return option;
  }

  getListItemData (element) {
    const $link = $(element).find('a');
    const href = $link.attr('href').replace('#', '');
    const text = $link.text().trim();

    return { $link, href, text };
  }
}
