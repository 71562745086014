import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

let Masonry = false;

try {
  Masonry = require('masonry-layout');
} catch (e) {
  if (e instanceof Error && e.code === "MODULE_NOT_FOUND")
    console.info("Masonry module not found : please run npm install masonry-layout");
  else
    throw e;
}

export default class NewsRoom extends PewComponent {

  init() {
    this.recomputeIndexes();
    if (Masonry) {
      this.registerMasonry();
    }
    this.registerSearch(true);
    this.registerPagination(this.element.find('.pagination'));
  }

  registerMasonry() {
    if (!this.isMobile()) {
      const $masonryWrap = this.element.find('.newsroom-grid');
      this.element.addClass('newsroom-hasmasonry');
      this.masonryInstance = new Masonry(
        $masonryWrap[0],
        this.getMasonryOpts($masonryWrap)
      );
    } else {
      Masonry = false;
    }
  }

  isMobile() {
    return (window.innerWidth < 768);
  }

  getMasonryOpts($masonryWrap) {
    let opts = {
      // options
      itemSelector: '[data-block-content-type]',
      gutter: 20
    };

    //Recup / override des options par data attribut
    const dataSet = $masonryWrap.data();
    if (dataSet) {
      for (let i in dataSet) {
        if (i.includes("masonry")) {
          let optIndex = this.camelize(i.replace('masonry', ''));
          opts[optIndex] = dataSet[i];
        }
      }
    }
    //console.log(opts);
    return opts;
  }

  camelize(str) {
    return str.toLowerCase().replace(
      /([-_][a-z])/g,
      (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  }

  recomputeIndexes() {
    jQuery('.module-newsroom').each(function (i, instance) {
      $(instance).data('index', i);
    });
  }

  loadPage(href, isPagination, cb) {
    var self = this;
    this.element.addClass('ajax-loading');
    $.get(href, {}, (res) => {
      this.element.removeClass('ajax-loading');
      var index = this.element.data('index');
      var $newContext = null;
      if (index) {
        $newContext = $($(res).find('.module-newsroom')[index]);
        $newContext.data('index', index);
      } else {
        $newContext = $(res).find('.module-newsroom').first();
      }

      this.handleNewContext($newContext, isPagination, cb);
    });
  }

  handleNewContext($newContext, isPagination, cb) {

    const mode = this.element.data('mode');
    let $newItems;

    if (mode === 'type_columns') {
      $newItems = $newContext.find('.wall-blocks-grid > *');
      $newItems.each((i, newLoadedColumn) => {
        let selector = '.' + newLoadedColumn.classList.toString().replace(/ /g, '.');
        let $existingCol = this.element.find(selector);
        if ($existingCol.length) {
          $existingCol.find('> ul').append($(newLoadedColumn).find('> ul').html());
        }
      });
    } else {
      $newItems = $newContext.find('.newsroom-grid > *');
      if (Masonry) {
        if (!isPagination || this.element.find('.pagination-mode-full').length > 0) {
          this.masonryInstance.remove(this.element.find('.newsroom-grid > *'));
        }
        this.element.find('.newsroom-grid').append($newItems);
        this.masonryInstance.appended($newItems);
        this.masonryInstance.layout();
      } else {
        if (!isPagination || this.element.find('.pagination-mode-full').length > 0) {
          this.element.find('.newsroom-grid > *').remove();
        }
        this.element.find('.newsroom-grid')
          .append($newItems);
      }
    }

    this.replacePagination($newContext.find('.pagination'));

    if (window.pew) {
      window.pew.enhanceRegistry(this.element.find('.wall-blocks-grid')[0]);
    }
    cb && cb($newContext, $newItems);
  }

  registerPagination($pagination) {
    $pagination.find('a.navlink').on('click', (e) => {
      e.preventDefault();
      $(e.currentTarget).replaceWith('<span class="loader loader--small"></span>');
      this.loadPage(e.currentTarget.href, true, ($newContext, $newItems) => {
        this.newPageLoaded($newContext, $newItems)
      });
    })
  }

  newPageLoaded($newContext, $newItems) {

  }

  replacePagination($newPagination) {
    this.element.find('.pagination').replaceWith($newPagination);
    this.registerPagination($newPagination);
  }

  registerSearch(autoSubmit) {
    let $filtersForm = this.element.find('form.newsroom-filters');

    $filtersForm.on('submit', (e) => {
      e.preventDefault();
      if (!this.element.hasClass('ajax-loading')) {
        this.handleFiltersSubmit($filtersForm);
      }
    });
    if (autoSubmit) {
      $filtersForm.addClass('auto-submit');

      //auto submit on input change
      const $inputs = $filtersForm.find('.checkbox, input, select').not('.exclude');
      $inputs.on('change', function () {
        $(this).parent().addClass('currently-loading');
        $filtersForm.submit();
      });

      //Possibility to uncheck a radio input
      $("input:radio:checked").data("chk", true);
      $inputs.on('click', function () {
        $(this).data("chk", !$(this).data("chk"));
        $(this).prop("checked", $(this).data("chk")).trigger('change');
      });
    }
  }

  handleFiltersSubmit($filtersForm) {
    this.loadPage($filtersForm.prop('action') + '?' + $filtersForm.serialize(), false, ($newContext, $newItems) => {
      this.newPageLoaded($newContext, $newItems)
    });
  }

  serializeAssoc(rawFields) {
    let fields = {};
    $.each(rawFields, (i, field) => {
      fields[field.name] = field.value;
    });
    return fields;
  }

}

window.pew.addRegistryEntry({key: 'wwp-newsroom', classDef: NewsRoom, domSelector: '.module-newsroom'});
