import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class AlerteComponent extends PewComponent {

  init() {
    this.registerCloseEvent();
  }

  registerCloseEvent() {
    const $closeTrigger = this.element.find(this.options.closeTriggerSelector);
    if ($closeTrigger.length) {
      $closeTrigger.on('click', (e) => {
        this.close();
      });
    }
  }

  reveal() {
    this.element.fadeIn(this.options.transitionSpeed, () => {
      this.options.onReveal && this.options.onReveal(this);
    });
  }

  close() {
    this.element.fadeOut(this.options.transitionSpeed, () => {
      this.options.onClose && this.options.onClose(this);
    });
  }
}
