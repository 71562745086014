import "modaal/dist/js/modaal";
import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class GalerieModalComponent extends PewComponent {
  init(){
    this.registerGallery();
  }
  registerGallery(){
    this.element.find(".gallery").modaal({
      type: 'image'
    });
  }
}
// Default
window.pew.addRegistryEntry({key: "wdf-galerie-modal", domSelector: ".gallery-group", classDef: GalerieModalComponent});
