import {SearchPanel} from "./SearchPanel";

export class SearchTriggerComponent {

    constructor(element, options) {
        this.element = (element instanceof jQuery) ? element : $(element);

        this.options = {};
        this.setOptions(options);

        this.init();
    }

    setOptions(options) {
        this.options = Object.assign(this.options, options);
    }

    init() {
        this.registerSearch();
    }

    getSearchTriggerElement() {
        return this.element;
    }

    registerSearch() {
        let searchField = this.getSearchTriggerElement();

        searchField.on('click', (e) => {
            e.preventDefault();
            if (!this.element.data('panel')) {
                let panel = new SearchPanel({
                    searchLink: e.currentTarget.getAttribute('href')
                });
                $('body').append(panel.$markup);
                setTimeout(() => {
                    panel.open();
                }, 200);
                this.element.data('panel', panel);
            } else {
                this.element.data('panel').toggle();
            }
        });

    }

}

window.pew.addRegistryEntry({key: 'search-trigger', domSelector: '.search-trigger', classDef: SearchTriggerComponent});
