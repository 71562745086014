import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class DirectLinksComponent extends PewComponent {
  init() {
    this.logDebug('Created a new direct links component with element : ', this.element);
    this.registerNavEvents();
  }

  registerNavEvents() {
    this.element.find('.direct-link').on('click', (e) => {
      e.preventDefault();
      let $thisLink = $(e.currentTarget);
      this.options.timelineComponent.scrollToPosition($thisLink.data('position'));
    });
  }

  activateBtn(slug) {
    this.logDebug('Activating button', slug);
    this.element.find('.' + slug + '-scene').prop('disabled', false);
  }

  deactivateBtn(slug) {
    this.logDebug('Deactivating button', slug);
    this.element.find('.' + slug + '-scene').prop('disabled', true);
  }

  logDebug() {
    if (this.options.debug) {
      console.info('[Parcours][DirectLinks]', ...arguments);
    }
  }
}
