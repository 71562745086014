import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class SceneComponent extends PewComponent {

  constructor(element, options) {
    let defaultOptions = {
      classToToggleWhenActive: 'active'
    }
    super(element, Object.assign(defaultOptions, options));
  }

  init() {
    this.active = false;
    this.logDebug('Created a new scene component at index [' + this.options.index + '] with element : ', this.element);
  }

  enter(e, cb) {
    this.logDebug('Entering scene ' + this.options.index + '', this, e);
    this.element.addClass(this.options.classToToggleWhenActive);
    cb && cb();
  }

  leave(e, cb) {
    this.logDebug('Leaving scene ' + this.options.index + '', this, e);
    this.element.removeClass(this.options.classToToggleWhenActive);
    cb && cb();
  }

  enableAnimations() {
    this.options.animationEnabled = true;
  }

  loadAudio() {
    this.audioLoaded = false;

    if (this.element.data('audio')) {
      this.audio = document.createElement('audio');

      this.audio.addEventListener('canplaythrough', () => {
        if (!this.audioLoaded) {
          this.audioLoaded = true;
        }
      }, false);

      this.audio.src = this.element.data('audio');
      this.audio.load();

    }
  }

  enableAudio() {
    this.options.audioEnabled = true;
  }

  disableAudio() {
    this.options.audioEnabled = false;
  }

  playAudio() {
    if (this.audioLoaded && this.options.audioEnabled) {
      let playPromise = this.audio.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {

        }).catch((errorType, error) => {
          //console.log('sound autoplay is disabled');
          this.options.audioEnabled = false;
        });
      }
    }
  }

  pauseAudio() {
    if (this.audioLoaded) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }

  logDebug() {
    if (this.options.debug) {
      console.info('[Parcours][Scene]', ...arguments);
    }
  }
}
