export class SearchComponent {

  constructor(element, options) {
    this.element = (element instanceof jQuery) ? element : $(element);

    this.options = {
      formSelector: '.search-form',
      searchInputSelector: '.search-input',
      resultsWrapSelector: '.search-results-wrap'
    };

    this.resultDom = '';

    this.setOptions(options);

    this.init();
  }

  setOptions(options) {
    this.options = Object.assign(this.options, options);
  }

  init() {
    this.focusSearchInput();
    this.registerAjaxSearch();
  }

  registerAjaxSearch() {
    this.element.find(this.options.formSelector).on('submit', (e) => {
      e.preventDefault();
      const form = e.target,
          $form = $(form);
      this.element.addClass('loading-results');
      this.element.find(this.options.resultsWrapSelector).html('<span class="loader"></span>');
      $.get(form.getAttribute('action'), $form.serialize(), (resultDom) => {
        this.resultDom = resultDom;
        this.replaceSearchResults(resultDom);
      });
      this.trackSearchEvent($form.find('input[name="s"]').val());
    })
  }

  replaceSearchResults(searchDom) {
    let resultsDom = $(searchDom).find(this.options.resultsWrapSelector);
    this.element.removeClass('loading-results');
    if (resultsDom.length) {
      this.element.find(this.options.resultsWrapSelector).replaceWith(resultsDom);
      this.registerCatOpeners();
      this.registerLinkOpeners();
      this.registerBackToResults();
      return true;
    }

    return false;
  }

  registerCatOpeners() {
    this.element.find('.search-all-res-in-cat').on('click', (e) => {
      e.preventDefault();
      let link = e.currentTarget;
      this.element.addClass('loading-results');
      this.element.find(this.options.resultsWrapSelector).html('<span class="loader"></span>');

      $.get(link.getAttribute('href'), {}, (resultDom) => {
        this.replaceSearchResults(resultDom);
      });
    })
  }

  registerBackToResults() {
    this.element.find('.search-go-back').on('click', (e) => {
      e.preventDefault();
      const reset = this.replaceSearchResults(this.resultDom);

      //In case we run into a problem, we reset the search form to trigger the search again.
      if(!reset){
        this.element.find(this.options.formSelector).submit();
      }
    })
  }

  registerLinkOpeners() {
    this.element.find('.set-results a').on('click', (e) => {
      $(document).trigger("searchLink:click", [e])
    });
  }

  focusSearchInput() {
    let $searchInput = this.element.find(this.options.searchInputSelector);
    if ($searchInput.length) {
      if ($searchInput.val().length) {
        $searchInput.select();
      } else {
        $searchInput.focus();
      }
    }
  }

  trackSearchEvent(searchQuery) {
    const EventManager = window.EventManager || $(document);
    EventManager.trigger('Tracking.customEvent', {
      label: searchQuery,
      category: 'search',
      action: 'search_query'
    });
  }

}

window.pew.addRegistryEntry({key: 'search-component', domSelector: '.search-component', classDef: SearchComponent});
