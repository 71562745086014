import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";
import {DirectLinksComponent} from "./DirectLinksComponent";
import {SceneComponent} from "./SceneComponent";
import {ScrollIndicator} from "./ScrollIndicatorComponent";
import {TimelineComponent} from "./TimelineComponent";

const ScrollMagic = require('ScrollMagic');
//require('animation.gsap');
require('debug.addIndicators');

jQuery.fn.reverse = [].reverse;

export default class ParcoursComponent extends PewComponent {

  constructor(element, options) {
    let defaultOptions = {
      animationsEnabled: true,
      debug: false,
      direction: element.getAttribute('data-layout'),
      scrollTo: 'center', // or 'top',
      hashNavigationEnabled: true,
      pinTimeline: true,
      timeline: {
        debug: false
      },
      directLinks: {
        debug: false
      },
      scrollIndicator: {
        debug: false
      },
      scenes: {
        debug: false,
        scrollOptions: {}
      }
    }
    super(element, Object.assign(defaultOptions, options));
  }

  init() {
    this.scenes = [];
    this.registerSubComponents();
    this.registerScroll();
    if (this.options.hashNavigationEnabled) {
      this.registerHashNavigation();
    }
  }

  registerSubComponents() {

    this.inited = [];

    //Timeline
    this.registerTimeline();

    //Direct Links
    //(first, prev, next, last)
    this.registerDirectLinks();

    //ScrollIndicator
    this.registerScrollIndicator();

    //Scenes
    this.registerScenes();

    this.logDebug('Inited : ', this.inited);
  }

  registerTimeline() {
    let $timelineElt = this.element.find('.timeline-component');
    if ($timelineElt.length) {
      this.options.timeline.scrollToStepCallback = this.scrollToStep.bind(this);
      this.timeline = this.getTimelineInstance($timelineElt);

      this.enableNavByCatLinks($timelineElt);

      this.inited.push('timeline');
    } else {
      this.timeline = null;
    }
  }

  enableNavByCatLinks($timelineElt) {
    const $categories = $timelineElt.find('.category-list-item');
    if ($categories.length) {
      $categories.find('> a').on('click', (e) => {
        e.preventDefault();
        const catLink = e.currentTarget,
          catLinkFrags = catLink.href.split('#'),
          catLinkHash = catLinkFrags[1];
        this.navigateByHash(catLinkHash);
      });
    }
  }

  getTimelineInstance($timelineElt) {
    return new TimelineComponent($timelineElt, this.options.timeline)
  }

  registerScrollIndicator() {
    let $scrollIndicatorElt = this.element.find('.parcours-scroll-indicator');
    if ($scrollIndicatorElt.length) {
      this.scrollIndicator = this.getScrollIndicatorInstance($scrollIndicatorElt);
      this.scrollIndicator.stopPlaying();
      this.inited.push('scrollIndicator');
    } else {
      this.scrollIndicator = null;
    }
  }

  getScrollIndicatorInstance($scrollIndicatorElt) {
    return new ScrollIndicator($scrollIndicatorElt, this.options.scrollIndicator);
  }

  registerScenes() {
    let $scenes = this.element.find('.scene'),
      scenesLength = $scenes.length;
    if (scenesLength) {
      $scenes.each((i, scene) => {
        let sceneOpts = this.options.scenes;
        sceneOpts.animationsEnabled = this.options.animationsEnabled;
        sceneOpts.index = i;
        sceneOpts.positionType = (i === 0) ? 'first' : (i === (scenesLength-1) ? 'last' : 'common');
        this.scenes.push(this.getSceneComponentInstance($(scene), sceneOpts));
      });
      this.inited.push('scenes (' + scenesLength + ')');
    }
  }

  registerDirectLinks() {
    let $directLinks = this.element.find('.direct-links');
    if ($directLinks.length && this.timeline) {
      this.options.directLinks.timelineComponent = this.timeline;
      this.directLinks = new DirectLinksComponent($directLinks, this.options.directLinks);
    }
  }

  getSceneComponentInstance($sceneElt, sceneOpts) {
    return new SceneComponent($sceneElt, sceneOpts);
  }

  registerScroll() {
    if (ScrollMagic) {
      const isControllerVertical = (this.options.direction === 'vertical');
      this.scrollController = new ScrollMagic.Controller({
        vertical: isControllerVertical
      });

      //Register scenes scroll
      for (let i in this.scenes) {
        let scrollMagicScene = this.initScene(i);
        scrollMagicScene.addTo(this.scrollController);
      }

      //Register TimelineScroll
      if (this.options.pinTimeline && isControllerVertical && this.timeline) {
        let scrollMagicTimeline = this.getTimelineScene(this.timeline);
        scrollMagicTimeline.addTo(this.scrollController);
      }

    } else {
      console.warn('[Parcours] The Scroll Magic library is required by this plugin. Without it, all scroll functionalities are missing. Please run `npm install janpaepke/ScrollMagic#master --save` to install it.');
    }

    if (this.scrollIndicator) {
      let isScrollingTimeout;
      window.addEventListener('scroll', (event) => {
        if (!this.scrollIndicator.playing) {
          this.scrollIndicator.startPlaying();
        }
        window.clearTimeout(isScrollingTimeout);
        isScrollingTimeout = setTimeout(() => {
          this.scrollIndicator.stopPlaying();
        }, 200);
      }, false);
    }
  }

  initScene(i) {
    i = parseInt(i);
    const sceneComponent = this.scenes[i];

    let scrollSceneOpts = this.getSceneOtps(i);

    scrollSceneOpts = Object.assign(scrollSceneOpts, this.options.scenes.scrollOptions);

    let scrollMagicScene = new ScrollMagic.Scene(scrollSceneOpts)

      .on("enter", (e) => {
        if (this.timeline) {
          this.timeline.activateStep(i);
          if (this.directLinks) {
            if (i === 0) {
              this.directLinks.deactivateBtn('first')
              this.directLinks.deactivateBtn('prev')
            }
            if (i === (this.scenes.length - 1)) {
              this.directLinks.deactivateBtn('next')
              this.directLinks.deactivateBtn('last')
            }
          }
        }
        sceneComponent.enter(e);
      })
      .on("leave", (e) => {
        if (this.timeline) {
          this.timeline.deactivateStep(i);
          if (this.directLinks) {
            if (i === 0) {
              this.directLinks.activateBtn('first')
              this.directLinks.activateBtn('prev')
            }
            if (i === (this.scenes.length - 1)) {
              this.directLinks.activateBtn('next')
              this.directLinks.activateBtn('last')
            }
          }
        }
        sceneComponent.leave(e);
      })
    ;
    if (scrollSceneOpts.addIndicators) {
      scrollMagicScene.addIndicators() // add indicators (requires plugin)
    }

    setTimeout(() => {
      sceneComponent.enableAnimations();
    }, 100);
    return scrollMagicScene;
  }

  getTimelineScene(timelineInstance) {
    const timelineElement = timelineInstance.element[0];
    return new ScrollMagic.Scene({
      triggerElement: timelineElement,
      duration: this.element.outerHeight(),
      triggerHook: 0
    })
      .setPin(timelineElement)
  }

  scrollToStep(index) {
    this.logDebug('Scrolling controller to scene : ', index);
    if (this.scrollController) {
      let $scrollElement = $('html, body');
      let newScrollPos = this.getScrollToStepPosition(index);

      if (this.options.direction === 'horizontal') {
        $scrollElement = this.element.find('.scenes-wrap')

        this.scrollController.scrollTo(function (newScrollPos, $scrollElement) {
          $scrollElement.animate({scrollLeft: '+=' + newScrollPos});
        });

        this.scrollController.scrollTo(newScrollPos, $scrollElement);
      } else {
        this.scrollController.scrollTo(function (newScrollPos, $scrollElement) {
          $scrollElement.animate({scrollTop: newScrollPos});
        });

        if (window.smoothScrollMargin) {
          newScrollPos -= window.smoothScrollMargin;
        }

        let $adminBar = $('#wpadminbar');
        if ($adminBar.length) {
          newScrollPos -= $adminBar.height();
        }

        this.scrollController.scrollTo(newScrollPos, $scrollElement);
      }
    }
  }

  logDebug() {
    if (this.options.debug) {
      console.info('[Parcours]', ...arguments);
    }
  }

  getSceneOtps(i) {
    const sceneComponent = this.scenes[i];
    const durationCallback = this.getDurationCallback(i);

    return {
      triggerElement: sceneComponent.element[0],
      duration: durationCallback,
    };
  }

  getScrollToStepPosition(index) {
    let sceneComponent = this.scenes[index];

    if (this.options.direction === 'horizontal') {
      const isSceneSmallerThanViewport = sceneComponent.element.outerWidth() < $(window).width();
      if (isSceneSmallerThanViewport && this.options.scrollTo === "center") {
        const emptySceneViewportWidth = $(window).width() - sceneComponent.element.outerWidth();
        return sceneComponent.element.offset().left - (emptySceneViewportWidth / 2);
      }

      return sceneComponent.element.offset().left;
    }

    const isSceneSmallerThanViewport = sceneComponent.element.outerHeight() < $(window).height();
    if (isSceneSmallerThanViewport && this.options.scrollTo === "center") {
      const emptySceneViewportHeight = $(window).height() - sceneComponent.element.outerHeight();
      return sceneComponent.element.offset().top - (emptySceneViewportHeight / 2);
    }

    return sceneComponent.element.offset().top;
  }

  getDurationCallback(i, offset = 0) {
    const sceneComponent = this.scenes[i];

    const getSize = () => {
      return this.options.direction === 'vertical' ? sceneComponent.element.outerHeight() : sceneComponent.element.outerWidth();
    }

    let duration = getSize();

    $(window).resize(() => {
      duration = getSize();
    })

    return () => {
      return duration + offset
    };
  }

  registerHashNavigation() {
    $(window).on('hashchange', () => {
      this.navigateByHash(window.location.hash.replace('#', ''));
    });
    this.navigateByHash(window.location.hash.replace('#', ''));
  }

  navigateByHash(hash) {
    let sceneIndex = null;
    if (hash && hash.length) {
      sceneIndex = this.locateSceneByHash(hash);

      if (sceneIndex !== null) {
        this.scrollToStep(sceneIndex);
      }
    }
    return sceneIndex;
  }

  locateSceneByHash(hash) {
    let $sceneElement = null;

    //Locate by custom anchor
    const $sceneElementInner = this.element.find('[data-anchor="' + hash + '"]');
    if ($sceneElementInner.length) {
      $sceneElement = $sceneElementInner.parent();
      return $sceneElement.index();
    }

    //Locate by id
    $sceneElement = this.element.find('#' + hash);
    if ($sceneElement.length) {
      return $sceneElement.index()
    }

    return null;
  }
}

window.pew.addRegistryEntry({
  key: 'parcours-component',
  domSelector: '.module-wwp-parcours .parcours-detail',
  classDef: ParcoursComponent
});
