/**
 * vote.js. Created by jeremydesvaux the 16 mai 2014
 */
(function ($, ns) {

  "use strict";

  /**
   * init module scripts, relative to its context (multiple context of the same module may exist in a page)
   * @param context jQuery context wraper div of the module
   */
  var vote = function (context) {
    this.$context = (context instanceof jQuery) ? context : $(context);
    this.EventManager = window.EventManager || $(document);
    this.defaultError = 'A fatal error occurred while trying to cast your vote.<br />Please try again later.';
    this.init();
  };

  vote.prototype = {
    init: function () {
      const cacheEnabled = wonderwp.cache_enabled;
      if (cacheEnabled) {
        this.refreshFormTokens();
      }
      this.registerFormSubmit();
      this.registerVoteChange();
    },
    registerFormSubmit: function () {
      var t = this;
      t.$context.find('form.voteForm').on('submit', function (e) {
        e.preventDefault();

        t.removeNotifications();

        var $form = $(this),
          formData = $form.serializeArray();

        if ($form.hasClass('form-type-star')) {
          var jsTemplates = document.querySelector('#jsTemplates');
          jsTemplates = JSON.parse(jsTemplates.innerHTML);
          var tpl = jsTemplates['loaders'],
            $loader = $(tpl['loader-alt-small']);
          $form.append($loader);
        }

        $form.addClass('loading').find('input').attr('disabled', 'disabled');
        $.post($form.attr('action'), formData, function (res) {

          if (res && res.code && res.code === 200) {
            t.onVoteSuccess(res, $form);
          } else {
            t.onVoteError(res, $form);
          }
          $form.removeClass('loading').find('input').removeAttr('disabled', 'disabled');
          if ($loader && $loader.length) {
            $loader.remove();
          }
        })
          .fail(function (jqXHR, textStatus, errorThrown) {
            t.onVoteError({code: 500, msg: t.defaultError}, $form);
          });
      })
    },
    removeNotifications: function () {
      var $alert = this.$context.find('.alert');
      if ($alert.length) {
        $alert.remove();
      }
    },
    onVoteSuccess: function (res, $form) {
      this.notify('success', res.data.msg, this.$context);
      if (res && res.data && !res.data.update) {
        this.incrementVoteCount();
      }
      this.changeVoteState($form, true);
    },
    incrementVoteCount: function () {
      var $nbVotes = this.$context.find('.nbVotes');
      if ($nbVotes && $nbVotes.length) {
        var $counter = this.$context.find('.count');
        if ($counter && $counter.length) {
          var currentCount = parseInt($counter.text());
          currentCount++;
          $counter.text(currentCount);
          if (currentCount > 1 && $nbVotes.data('vote-plural-word')) {
            var $trad = $nbVotes.find('.trad');
            if ($trad && $trad.length) {
              $trad.text($nbVotes.data('vote-plural-word'));
            }
          }
        }
      }
    },
    changeVoteState: function ($form, hasVoted) {
      var $submitBtn = this.$context.find('button[type="submit"]');

      if (hasVoted) {
        $form.attr('data-av', 1);
        $submitBtn.text($submitBtn.data('voted-text'));
      } else {
        $form.attr('data-av', 0);
        $submitBtn.text($submitBtn.data('vote-text'));
      }
    },
    onVoteError: function (res, $form) {
      var errorMsg = res && res.data && res.data.msg ? res.data.msg : this.defaultError;
        this.notify('error', errorMsg, this.$context);
    },
    registerVoteChange: function () {
      var t = this;
      t.$context.find('input.radio').on('change', function () {
        t.$context.find('form.voteForm').submit();
      });
    },
    notify: function (type, msg, $context) {
      this.EventManager.trigger('notification', {
        type: type,
        msg: msg,
        dest: $context,
        focus: false,
        delay: type==='success' ? 1000 : 2000
      });
    },
    refreshFormTokens: function () {
      var t = this;
      this.EventManager.on('wwp-cache-onCacheLoadSuccess', function (event, eventData) {
        var res = eventData.res;
        if (res.data['wwp-vote'] && res.data['wwp-vote']['secrets']) {
          var secrets = res.data['wwp-vote']['secrets'];
          for (var i in secrets) {
            t.$context.find('input[name=' + i + ']').val(secrets[i]);
          }
        }
      });
    }
  };

  if (ns && ns.app) {
    ns.app.registerModule('vote', vote);
  } else {
    window.pew.addRegistryEntry({key: 'wdf-vote', domSelector: '.module-vote', classDef: vote});
  }

})(jQuery, window.wonderwp);

