import {PewComponent} from "../../../../themes/wwp_child_theme/assets/raw/js/components/pew-component";

export class ScrollIndicator extends PewComponent {
  init() {
    this.playing = false;
  }

  startPlaying() {
    this.logDebug('play');
    this.playing = true;
    this.element.addClass('playing');
  }

  stopPlaying() {
    this.logDebug('stop');
    this.playing = false;
    this.element.removeClass('playing');
  }

  logDebug() {
    if (this.options.debug) {
      console.info('[Parcours][ScrollIndicator]', ...arguments);
    }
  }
}
